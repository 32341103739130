import gql from "graphql-tag";
import vacancyFragment from "./VacancyFragment";
import VacancyFragmentPaginate from "./VacancyFragmentPaginate";

export const GET_VACANCIES_ENTITY_QUERY = gql`
  query GetVacanciesEntity($private: Boolean, $page: Int, $pageSize: Int) {
    vacancies: getVacanciesEntity(private: $private, page:$page, pageSize:$pageSize) {
      ...${vacancyFragment}
    }
  }
`;

export const GET_VACANCIES_ENTITY_PAGINATE_QUERY = gql`
  query GetVacanciesEntityPaginate($private: Boolean,$name:String, $page: Int, $pageSize: Int) {
    vacancies: getVacanciesEntityPaginate(private: $private,name:$name, page:$page, pageSize:$pageSize) {
      ...${VacancyFragmentPaginate}
    }
  }
`;

export const GET_NOTES_VACANCY_CANDIDATE_QUERY = gql`
  query getNotesVacancyCandidate($vacancyId: ID!, $candidateId: ID!) {
    notes: getNotesVacancyCandidate(
      vacancyId: $vacancyId
      candidateId: $candidateId
    ) {
      name
      text
      time
      ranking
    }
  }
`;

export const GET_PUBLISHED_VACANCIES_QUERY = gql`
  query GetPublishedVacancies(
    $ranking: Boolean
    $name: String
    $page: Int
    $pageSize: Int
    $vacancyId: ID
  ) {
    vacancies: getPublishedVacancies(
      ranking: $ranking
      name: $name
      page: $page
      pageSize: $pageSize
      vacancyId: $vacancyId
    ) {
      id
      title
      essentialSkills
      stages {
        id
        name
        analyze
        startDate
        startTime
        endDate
        endTime
      }
      entity {
        id
        name
      }
      candidacies {
        appliedFor
        created
        candidate
        documents
        skills
        stages {
          name
          analyze
          rating
          status
          startDate
          startTime
          endDate
          endTime
          participants {
            id
            name
            photo
          }
        }
        tests {
          id
          candidate
          date
          description
          image
          questions {
            question
            objective
            implication
            file
            fileResponse
            openQuestion
            alternatives {
              answer
              response
              value
              textResponse
            }
            observations {
              id: _id
              name
              time
              result
              textObservation
            }
            answer
            totalCorrect
            percentage
            picture
          }
          result
          stage
          status
          time
          title
          type
          # user {
          #   id
          #   name
          #   office
          #   photo
          # }
          vacancy {
            id
          }
        }
      }
      favorites {
        id
        candidates
        name
      }
      finalDate
      publicationDate
      weight
      areaInterest
      professionalExperience {
        description
        yearsExperience
        weight
        sector
      }
      formation {
        course
        level
        weight
      }
      languages {
        name
        level
        weight
      }
    }
  }
`;

export const GET_VACANCY_LIST_QUERY = gql`
  query GetVacancyList($id: ID!) {
    lists: getVacancyList(id: $id) {
      id
      candidates
      name
    }
  }
`;

export const GET_USERS_ENTITY_QUERY = gql`
  query GetUsersEntity {
    users: getUsersEntity {
      id
      email
      name
      office
      photo
    }
  }
`;

export const GET_VACANCY_BY_ID_QUERY = gql`
  query GetVacancyById($id: ID!, $fromProfile: Boolean) {
    vacancy: getVacancyById(id: $id, fromProfile: $fromProfile) {
      ...${vacancyFragment}
    }
  }
`;
export const GET_NEW_VACANCY = gql`
  query getNewVacancies {
    getNewVacancies {
      title
      available
      finalDate
      candidacies {
        id
        appliedFor
      }
      entity {
        id
      }
    }
  }
`;

export const GET_VACANCY_BY_ID_GUEST_QUERY = gql`
  query GetVacancyByIdGuest($id: ID!) {
    vacancy: getVacancyByIdGuest(id: $id) {
      id
      available
      client {
        id
        name
      }
      description
      entity {
        id
        logo
        name
      }
      finalDate
      formation {
        course
        level
        status
        institution
        year
      }
      languages {
        name
        level
      }
      locale
      category
      place
      private
      professionalExperience {
        description
        yearsExperience
      }
      publicationDate
      status
      title
      type
      views
    }
  }
`;
