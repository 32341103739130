import gql from "graphql-tag";

export default gql`
  {
    id
    areas
    category
    creatorType
    description
    difficultyLevel
    duraction
    entity
    image
    openResponse
    positions
    price
    pub
    publish
    questions {
      question
      objective
      implication
      file
      openQuestion
      alternatives {
        answer
        value
        textResponse
      }
      totalCorrect
      percentage
      picture
    }
    status
    isDraft
    reason
    title
    type
  }
`;
