module.exports = {
  academicEducation: "Academic education",
  availability: "Availability",
  languages: "Languages",
  professionalExperience: "Professional Experience",
  year: "Year",
  years: "Years",
  postingForm: "Posting form",
  toView: "To view",
  clienteName: "Select client",
  typeVacancy: "Post type",
  category: "Category",
  jobTitle: "Job Title",
  language: "Language",
  level: "Level",
  doc_label: "Required document",
  doc_rule_label: "Category",
  academicEdu: "Academic education",
  yearExperiencie: "Years of experience",
  provinceState: "Province/County",
  enterJobDesc: "Enter the job description",
  startDate: "Start date",
  endDate: "End date",
  tvacancy: ["Job ofering", "Internship offer"],
  categoryOptions: ["Fulltime", "Part time", "Freelancer"],
  languageOptions: [
    "Portuguese",
    "English",
    "French",
    "German",
    "Spanish",
    "Barwe",
    "Chewa",
    "Chiyao",
    "Chopi",
    "Chuwabu",
    "Dema",
    "Kokola",
    "Koti",
    "Kunda",
    "Lolo",
    "Lomwe",
    "Maindo",
    "Makhuwa",
    "Makonde",
    "Makwe",
    "Manyawa",
    "Marenje",
    "Mwani",
    "Nathembo",
    "Ndau",
    "Ngoni",
    "Nsenga",
    "Nyungwe",
    "Phimbi",
    "Ronga",
    "Sena",
    "Shona",
    "Swahili",
    "Swati",
    "Takwane",
    "Tawara",
    "Tewe",
    "Tonga",
    "Tsonga",
    "Tswa",
    "Zulu",
  ],
  documents: [
    { key: "doc_1", text: "Motivation letter" },
    { key: "doc_2", text: "Curriculum Vitae" },
    { key: "doc_3", text: "Certificates" },
    { key: "doc_4", text: "Reference letter" },
  ],
  doc_rules: [
    { key: "rule_1", text: "Required" },
    { key: "rule_2", text: "Optional but relevant" },
  ],
  levelLanguageOptions: ["Basic", "Intermediate", "Advanced"],
  levelLanguageOptions1: [
    { text: "Basic", key: "basic" },
    { text: "Intermediate", key: "intermediate" },
    { text: "Advanced", key: "advanced" },
  ],
  levelAcademicOptionsEN: [
    { key: "basic", text: "Basic" },
    { key: "elementary", text: "Elementary" },
    { key: "dedium", text: "Medium" },
    { key: "bachelor", text: "Bachelor" },
    { key: "graduation", text: "Graduation" },
    { key: "master_degree", text: "Master degree" },
    { key: "doctorate", text: "Doctorate" },
    { key: "Phd", text: "Phd" },
  ],
  levelAcademicOptions: [
    "1 Twelve great or Less",
    "2 Twelve great",
    "3 Tech trening",
    "4 Degree",
    "5 Master degree",
    "6 Phd",
  ],
  requiredField: "Required field",
  finish: "Finish",
  publish: "Publish",
  closeSave: "Save as draft",
  proceed: "proceed",
  alert: "Complete your profile to be able to continue",
  weight: "Score",
  area: "Related areas",
  add_name_stage: "Add the name of the stage, e.g., Resume Screening",
  what_aspects_intend_analyze:
    "What aspects do you intend to analyze in the above-mentioned stage?",
  essential_skills_and_competencies: "Essential skills and competencies",
  essential_skills_and_competencies_long:
    "Define lists of essential skills and competencies that candidates must possess to be eligible for the position. These lists will be available as 'tags' for selection during the application process.",
};
